<template>
  <b-card>

    <validation-observer
      ref="userRules"
      tag="form"
    >

      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-img
            ref="previewLogo"
            class="user-avatar"
            rounded
            :src="userData.avatar"
            height="80"
          />
          <!--/ avatar -->
        </b-media-aside>

        <validation-provider
          #default="{ errors }"
          name="logo image"
          :rules="{
            required: !isFileRequired,
          }"
        >
          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputLogo.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputLogo"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputLogoRenderer"
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              @click="userData.avatar = ''"
            >
              Reset
            </b-button>
            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
          </b-media-body>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >The program logo image is required</small>
        </validation-provider>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-row class="mt-2">
        <b-col sm="6">
          <b-form-group
            label="Program Name"
          >
            <validation-provider
              #default="{ errors }"
              name="program title"
              rules="required"
            >
              <b-form-input
                v-model="userData.title"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Program Date"
          >
            <validation-provider
              #default="{ errors }"
              name="program date"
              rules="required"
            >
              <flat-pickr
                v-model="userData.date"
                class="form-control"
                :class="{'is-invalid': errors.length > 0 }"
                :config="{ mode: 'range'}"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Description"
          >
            <validation-provider
              #default="{ errors }"
              name="program description"
              rules="required"
            >
              <quill-editor
                v-model="userData.description"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mt-2 mb-3">

      <b-row>

        <b-col sm="6">
          <b-form-group
            label="Capacity"
          >
            <validation-provider
              #default="{ errors }"
              name="сapacity"
              rules="required"
            >
              <b-form-input
                v-model="userData.capacity"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Gender"
          >
            <validation-provider
              #default="{ errors }"
              name="gender"
              rules="required"
            >
              <v-select
                v-model="userData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Boys', 'Girls', 'Both']"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Min Age"
          >
            <validation-provider
              #default="{ errors }"
              name="min age"
              rules="required"
            >
              <b-form-input
                v-model="userData.minAge"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Max Age"
          >
            <validation-provider
              #default="{ errors }"
              name="max age"
              rules="required"
            >
              <b-form-input
                v-model="userData.maxAge"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mt-2 mb-3">

      <!-- <b-row>

          <b-col sm="6">
            <b-form-group
              label="Payment Plan"
            >
              <b-form-checkbox
                v-model="paymentPlan.is_enable"
                switch
                :value="true"
              >
                True
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Max payment allowed"
            >
              <b-form-spinbutton
                v-model="paymentPlan.max_months"
                min="0"
                max="10"
                class="w-25"
                :disabled="!paymentPlan.is_enable"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <hr class="mt-2 mb-3"> -->

      <b-row>

        <b-col sm="6">
          <b-form-group
            label="Virtual Program"
          >
            <b-form-checkbox
              v-model="userData.virtualProgram"
              :value="true"
              switch
            >
              True
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Active"
          >
            <b-form-checkbox
              v-model="userData.enableRegistration"
              :value="true"
              switch
            >
              True
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Hide Price"
          >
            <b-form-checkbox
              v-model="userData.hidePrice"
              :value="true"
              switch
            >
              True
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mt-2 mb-3">

      <b-row>

        <b-col sm="6">
          <b-form-group
            label="Payment Contract"
          >
            <v-select
              v-model="paymentContract.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentContractsList"
              :reduce="option => option.id"
              :label="'name'"
              placeholder="Select Payment Contract"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <hr class="mt-2 mb-3">

      <b-row>

        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="CameraIcon"
              size="21"
            />
            <h3 class="mb-0 ml-75 font-weight-normal">
              Slider Images
            </h3>
          </div>
        </b-col>

        <div
          class="mb-1 px-1"
        >
          <div
            class="d-flex align-items-stretch justify-content-start flex-wrap"
          >

            <div
              v-for="(item, index) in userData.gallery"
              :key="index"
              class="mr-1 media-item"
            >
              <b-img
                ref="previewEl"
                rounded
                :src="item"
                height="80"
              />

              <b-button
                size="sm"
                @click.prevent="deletePhoto(index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                />
              </b-button>
            </div>

            <div
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                @click="$refs.refInputEl.$el.click()"
              >
                Add
              </b-button>
              <b-form-file
                ref="refInputEl"
                v-model="profileFile"
                accept=".jpg, .png, .gif"
                :hidden="true"
                multiple
                plain
                @input="inputImageRenderer"
              />
            </div>

          </div>
        </div>
      </b-row>

      <hr class="mt-2 mb-3">

      <b-row>

        <b-col sm="6">
          <p>Who Applies to the Program</p>
        </b-col>

        <b-col sm="6">
          <validation-provider
            #default="{ errors }"
            name="role"
            rules="required"
          >
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :clearable="false"
              value="id"
              :reduce="role => role.id"
              :class="{'is-invalid': errors.length > 0 }"
              multiple
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="handleForm"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            :to="{ name: 'admin-program'}"
            @click.prevent="resetForm"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>

    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BFormGroup, BFormInput, BRow, BCol, BCard,
  BCardText, BMedia, BMediaAside, BMediaBody, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'

export default {
  name: 'ProgramTabMain',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    quillEditor,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    paymentPlan: {
      type: Object,
      default: () => {},
    },
    paymentContract: {
      type: Object,
      default: () => {},
    },
    paymentContractsList: {
      type: Array,
      default: () => [],
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      profileFile: null,
      roleOptions: [
        {
          id: 'parent',
          label: 'Parent',
        },
        {
          id: 'child',
          label: this.camperStudent(1),
        },
        {
          id: 'staff',
          label: 'Staff',
        },
      ],
    }
  },
  computed: {
    isFileRequired() {
      return this.userData.avatar
    },
  },
  setup(props) {
    const refInputLogo = ref(null)
    const previewLogo = ref(null)

    const { inputImageRenderer: inputLogoRenderer } = useInputImageRenderer(refInputLogo, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
      // eslint-disable-next-line no-param-reassign
      props.userData.logoUploaded = base64
    })
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      props.userData.gallery.push(base64)
      props.userData.updatedGallery.push(base64)
    })

    const {
      canUpdateGeneralProgramSettings,
    } = useProgramsSetting()
    return {
      refInputLogo,
      previewLogo,
      refInputEl,
      previewEl,
      inputLogoRenderer,
      inputImageRenderer,
      canUpdateGeneralProgramSettings,
    }
  },
  methods: {
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('nextTab')
          } else {
            reject()
          }
        })
      })
    },
    deletePhoto(item) {
      this.userData.gallery.splice(item, 1)
      this.userData.updatedGallery.splice(item, 1)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/quill.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .user-avatar {
    min-width: 80px;
    background-color: rgba(0,0,0,.1);
  }
  .form-control[readonly] {
    background-color: inherit !important;
  }
</style>
