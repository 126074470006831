import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    programId: null,
    formsList: [],
    requirementsOptions: [
      'Not Required Step',
      'Required Step',
      'Required To Continue',
      'Requires Approval',
      'Requires Approval To Continue',
    ],
    allProgramStatusesList: [],
    statusesList: [],
    selectedStatus: null,
    stepModalType: '',
    selectedStep: '',
    registrationFormData: {
      id: null,
      activeStatus: false,
      conditions: [],
    },
    usePC: false,
    hidePB: false,
    conditionsArr: [],
    editingSettings: [],
    fieldsList: [],
    groupTypeOptions: [],
    type: 'Camper',
    applicationsForTagAutoAssign: [],
    tagIdsListForAutoAssign: [],
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getProgramId: state => state.programId,
    getFormsList: state => state.formsList,
    getRequirementOptions: state => state.requirementsOptions,
    getAllProgramStatusesList: state => state.allProgramStatusesList,
    getStatusesList: state => state.statusesList,
    getStatusesListWithoutDivider: state => state.statusesList.filter(status => !status.divider),
    getSelectedStatus: state => state.selectedStatus,
    getSelectedStatusIndex: state => state.statusesList.findIndex(item => item.id === state.selectedStatus.id),
    getStepModalType: state => state.stepModalType,
    getSelectedStep: state => state.selectedStep,
    getRegistrationFormData: state => state.registrationFormData,
    getEditingSettings: state => state.editingSettings,
    getProgramStepsList: (state, getters) => getters.getStatusesListWithoutDivider
      .reduce((arr, item) => {
        if (item.steps) arr.push(...item.steps)
        return arr
      }, []),
    getAllProgramStepsList: state => state.allProgramStatusesList
      .reduce((arr, item) => {
        arr.push(...item.steps)
        return arr
      }, []),
    getStepsFormsIDs: (state, getters) => getters.getProgramStepsList
      .reduce((arr, step) => {
        arr.push(step.form_id)
        return arr
      }, []),
    getStepsOptions: (state, getters) => getters.getProgramStepsList
      .reduce((arr, step) => {
        arr.push({
          value: step.id,
          text: step.name,
        })
        return arr
      }, []),
    getIsLastStatusInArray: (state, getters) => {
      const statusIndex = getters.getStatusesListWithoutDivider.findIndex(item => item.id === state.selectedStatus.id)
      return statusIndex === getters.getStatusesListWithoutDivider.length - 1
    },
    getUsePC: state => state.usePC,
    getHidePB: state => state.hidePB,
    getConditionsArr: state => state.conditionsArr,
    getFieldsList: state => state.fieldsList,
    getFieldsForConditions: state => state.fieldsList
      .filter(item => item.type !== null)
      .reduce((arr, item) => {
        arr.push({
          value: item.id,
          text: `${item.name} ${item.form_name ? `(${item.form_name})` : ''}`,
          type: item.type,
          meta: item.options,
          id: item.id,
        })
        return arr
      }, []),
    getGroupTypeOptions: state => state.groupTypeOptions,
    getType: state => state.type,
    getApplicationsForTagAutoAssign: state => state.applicationsForTagAutoAssign,
    getTagIdsListForAutoAssign: state => state.tagIdsListForAutoAssign,
  },
  mutations: {
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_FORM_TYPE: (state, val) => {
      state.type = val
    },
    SET_PROGRAM_ID: (state, val) => {
      state.programId = val
    },
    SET_FORMS_LIST: (state, val) => {
      state.formsList = val
    },
    SET_ALL_PROGRAM_STATUSES_LIST: (state, val) => {
      state.allProgramStatusesList = val
    },
    SET_STATUSES_LIST: (state, val) => {
      state.statusesList = val
    },
    PREPARE_STATUSES_LIST_WITH_DIVIDER: (state, val) => {
      const divider = {
        divider: true,
      }
      const dividerIndex = val.findIndex(status => status.is_acceptable)
      if (dividerIndex !== -1) {
        val.splice(dividerIndex, 0, divider)
      } else {
        val.push(divider)
      }
      state.statusesList = val
      state.usePC = state.statusesList.some(status => status.steps.some(step => step.has_payment_contract))
      state.hidePB = state.statusesList.some(status => status.steps.some(step => step.hide_price_breakdown))
    },
    RESET_STATUSES_LIST: state => {
      state.statusesList = []
    },
    RESET_CONDITIONS_LIST: state => {
      state.conditionsArr = []
    },
    SET_SELECTED_STATUS: (state, val) => {
      state.selectedStatus = val
    },
    DELETE_STATUS: (state, val) => {
      const statusIndex = state.statusesList.findIndex(item => item.id === val.id)
      state.statusesList.splice(statusIndex, 1)
    },
    UPDATE_STATUSES_POSITIONS_AND_APPLY_GROUPS: state => {
      const dividerIndex = state.statusesList.findIndex(status => status.divider)
      state.statusesList = state.statusesList.map((status, index) => {
        if (index <= dividerIndex) {
          return {
            ...status,
            position: index,
            is_acceptable: false,
          }
        }
        return {
          ...status,
          position: index - 1,
          is_acceptable: true,
        }
      })
      // state.statusesList = state.statusesList.map((status, index) => ({
      //   ...status,
      //   position: index,
      // }))
    },
    SET_STEP_MODAL_TYPE: (state, val) => {
      state.stepModalType = val
    },
    SET_SELECTED_STEP: (state, val) => {
      state.selectedStep = val
    },
    SET_USE_PC: (state, val) => {
      state.usePC = val
    },
    RESET_USE_PC: state => {
      state.usePC = false
    },
    SET_HIDE_PB: (state, val) => {
      state.hidePB = val
    },
    RESET_HIDE_PB: state => {
      state.hidePB = false
    },
    SET_REGISTRATION_FORM_DATA: (state, val) => {
      state.registrationFormData.id = val?.id || null
      state.registrationFormData.activeStatus = val?.is_active || false
      state.conditionsArr = val?.conditions || []
      state.editingSettings = val?.editing_settings || []
    },
    SET_REGISTRATION_FORM_STATUS: (state, val) => {
      state.registrationFormData.activeStatus = val
    },
    ADD_NEW_STEP: (state, val) => {
      const statusIndex = state.statusesList.findIndex(item => item.id === state.selectedStatus.id)
      state.statusesList[statusIndex].steps.push(val)
    },
    UPDATE_STEP: (state, val) => {
      const statusIndex = state.statusesList.findIndex(item => item.id === state.selectedStatus.id)
      const stepIndex = state.statusesList[statusIndex].steps.findIndex(item => item.id === state.selectedStep.id)
      Object.assign(state.statusesList[statusIndex].steps[stepIndex], val)
    },
    DELETE_STEP: (state, payload) => {
      const statusIndex = state.statusesList.findIndex(item => item.id === payload.statusId)
      const stepIndex = state.statusesList[statusIndex].steps.findIndex(item => item.id === payload.stepId)
      state.statusesList[statusIndex].steps.splice(stepIndex, 1)
    },
    UPDATE_PC_STEP: (state, payload) => {
      const hasPC = payload.has_payment_contract
      const hidePB = payload.hide_price_breakdown
      if (hasPC) {
        const stepsList = store.getters['app-program/getProgramStepsList']
        stepsList.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.has_payment_contract = false
          // eslint-disable-next-line no-param-reassign
          item.hide_price_breakdown = false
        })
        const step = stepsList.find(item => item.id === payload.id)
        step.has_payment_contract = hasPC
        step.hide_price_breakdown = hidePB
        state.usePC = hasPC
        state.hidePB = hidePB
      }
    },
    UPDATE_LAST_STEP: state => {
      const stepsList = store.getters['app-program/getProgramStepsList']
      stepsList.forEach(row => {
        // eslint-disable-next-line no-param-reassign
        row.has_payment_contract = false
        // eslint-disable-next-line no-param-reassign
        row.hide_price_breakdown = false
        // eslint-disable-next-line no-param-reassign
        row.is_last = false
      })
      const step = stepsList[stepsList.length - 1]
      step.is_last = true
      step.has_payment_contract = state.usePC
      step.hide_price_breakdown = state.hidePB
    },
    ADD_PROGRAM_ID_FOR_STEPS: state => {
      const stepsList = store.getters['app-program/getProgramStepsList']
      stepsList.forEach(step => {
        // eslint-disable-next-line no-param-reassign
        step.program_id = state.programId
      })
    },
    SET_CONDITIONS_ARR: (state, val) => {
      state.conditionsArr = val
    },
    SET_EDITING_SETTINGS: (state, val) => {
      state.editingSettings = val
    },
    SET_FIELDS_LIST: (state, val) => {
      state.fieldsList = val
    },
    SET_GROUP_TYPE_OPTIONS: (state, val) => {
      state.groupTypeOptions = val
    },
    SET_APPLICATIONS_FOR_TAG_AUTO_ASSIGN: (state, val) => {
      state.applicationsForTagAutoAssign = val
    },
    SET_TAGS_IDS_LIST_FOR_AUTO_ASSIGN: (state, val) => {
      state.tagIdsListForAutoAssign = val
    },
  },
  actions: {
    fetchProgramList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/programs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCommunicationSettingsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/mail-by-events', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEventsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/user-events', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRolesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/roles', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllEmailTemplatesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/email-templates', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/sessions', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchForms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/forms', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgramTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/tasks', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchAllProgramStatusesList({ state, commit }) {
      try {
        const queryParams = {
          programId: state.programId,
        }

        const response = await axios.get('/auth/registration-form-statuses', { params: queryParams })

        commit('SET_ALL_PROGRAM_STATUSES_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchRegistrationForm({ state, commit }) {
      try {
        const queryParams = {
          type: state.type,
          programId: state.programId,
        }
        const response = await axios.get('/auth/registration-forms/find-for-program', { params: queryParams })

        commit('SET_REGISTRATION_FORM_DATA', response.data.data)
        if (response.data.data.statuses) {
          commit('PREPARE_STATUSES_LIST_WITH_DIVIDER', response.data.data.statuses)
        } else {
          commit('PREPARE_STATUSES_LIST_WITH_DIVIDER', [])
        }
        return response
      } catch (error) {
        return error
      }
    },
    async prepareGroupTypes({ commit, state }) {
      try {
        const queryParams = {
          exceptProgramType: 'Program',
        }
        if (state.programId) {
          queryParams.programId = state.programId
        }
        const groupTypeOptions = await axios.get('/auth/group-types', { params: queryParams })
          .then(response => response.data.data.reduce((arr, item) => {
            arr.push({
              value: item.text,
              text: item.text,
              groups: item.groups,
            })
            return arr
          }, []))
        commit('SET_GROUP_TYPE_OPTIONS', groupTypeOptions)
        return groupTypeOptions
      } catch (error) {
        return error
      }
    },
    async prepareFieldsBySteps({ state, getters, commit }) {
      const queryParams = { formId: getters.getStepsFormsIDs }

      const fieldsList = await axios.get('/auth/fields', { params: queryParams })
        .then(fields => fields.data.data.map((item, index) => {
          if (item.type === 'group_selection') {
            return {
              frontID: index,
              delete: false,
              isCondition: false,
              isVisibility: false,
              ...item,
              options: state.groupTypeOptions.filter(groupType => groupType.text === item.default)[0]?.groups,
            }
          }
          return {
            frontID: index,
            delete: false,
            isCondition: false,
            isVisibility: false,
            form_name: state.formsList.find(form => form.id === item.form_id)?.name || '',
            ...item,
          }
        }))

      commit('SET_FIELDS_LIST', fieldsList)
    },
    // eslint-disable-next-line consistent-return
    async saveRegistrationForm({ commit, state, getters }, vm) {
      try {
        commit('ADD_PROGRAM_ID_FOR_STEPS')

        const queryParams = {
          id: state.registrationFormData.id,
          program_id: state.programId,
          conditions: state.conditionsArr,
          editing_settings: state.editingSettings,
          is_active: state.registrationFormData.activeStatus,
          statuses: getters.getStatusesListWithoutDivider,
          type: state.type,
        }
        const response = await axios.post('/auth/registration-forms', queryParams)
        commit('SET_REGISTRATION_FORM_DATA', response.data.data)
      } catch (error) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, please refresh the page',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
  },
}
