<template>
  <b-modal
    id="setup-status-events"
    ref="setup-status-events"
    centered
    size="xl"
    title="Set up status event"
    ok-title="Save changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    :ok-disabled="!canUpdateApplicationTriggers"
    @show="setModalData"
    @ok="postStatusTriggers"
    @hidden="resetModalData"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="setupStatusEvents"
        tag="form"
      >
        <template
          v-for="(triggerRow, index) in filteredTriggers"
        >
          <b-row
            :key="`trigger-row-${index}`"
            class="border-bottom my-2"
          >
            <b-col cols="11">
              <b-row>
                <!--  Status (from)  -->
                <b-col
                  sm="3"
                  class="mb-1"
                >
                  <b-form-group
                    label="Status From"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Status From"
                      rules="required"
                    >
                      <v-select
                        v-model="triggerRow.for_id"
                        disabled
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusesList"
                        placeholder="Select Status"
                        :clearable="false"
                        value="id"
                        label="name"
                        :reduce="value => value.id"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!--  Status (to)  -->
                <b-col
                  sm="3"
                  class="mb-1"
                >
                  <b-form-group
                    label="Status To"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Status To"
                      rules="required"
                    >
                      <v-select
                        v-model="triggerRow.to_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusesList"
                        placeholder="Select Status"
                        :clearable="false"
                        value="id"
                        label="name"
                        :reduce="value => value.id"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!--  Trigger  -->
                <b-col
                  sm="3"
                  class="mb-1"
                >
                  <b-form-group
                    label="Trigger"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="trigger"
                      rules="required"
                    >
                      <v-select
                        v-model="triggerRow.trigger"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="triggersOptions"
                        :placeholder="'Select Trigger'"
                        :clearable="false"
                        :reduce="value => value.value"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!--  Email Template  -->
                <b-col
                  v-if="triggerRow.trigger === 'Email' || triggerRow.trigger == null"
                  sm="3"
                  class="mb-1"
                >
                  <b-form-group
                    label="Email Template"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email template"
                      rules="required"
                    >
                      <v-select
                        v-model="triggerRow.email_template_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="emailTemplatesOptions"
                        :clearable="false"
                        :placeholder="'Select Email Template'"
                        value="id"
                        label="name"
                        :reduce="val => val.id"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Rich Text field  -->
                <b-col
                  v-else
                  sm="3"
                  class="mb-1"
                >
                  <b-form-group
                    label="Text"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="text"
                      rules="required"
                    >
                      <quill-editor
                        id="email-content"
                        v-model="triggerRow.message_text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Trigger Delete -->
            <b-col
              cols="1"
              class="align-self-center"
            >
              <SquareIcon
                v-if="canDeleteApplicationTriggers"
                size="21"
                icon="Trash2Icon"
                class="cursor-pointer"
                @onIconClick="removeRow(triggerRow.frontID)"
              />
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col
            sm="3"
          >
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center justify-content-center w-100 mt-2"
              :disabled="!canCreateApplicationTriggers"
              @click="addNewTriggerRow"
            >
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-1"
              />
              Add Trigger
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <div
        v-if="validationErrors.length"
        class="form-group"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center text-danger"
        >
          {{ validationError }}
        </p>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BButton,
  BFormGroup,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import useTriggersSetting from '@/views/admin/program/program-communication-list/useTriggersSetting'

export default {
  name: 'SetupStatusEventsModal',
  components: {
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BOverlay,

    ValidationObserver,
    ValidationProvider,
    vSelect,
    SquareIcon,
    quillEditor,
  },
  data() {
    return {
      rowsCount: 0,
      statusTriggers: [],
      emailTemplatesOptions: [],
      triggersOptions: [],
      validationErrors: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'app-program/getProgramId',
      statusesList: 'app-program/getStatusesList',
      selectedStatus: 'app-program/getSelectedStatus',
    }),
    filteredTriggers() {
      return this.statusTriggers.filter(trigger => !trigger.delete)
    },
  },
  setup() {
    const {
      fetchProgramTriggers,
      fetchTriggers,
      fetchEmailTemplates,
      saveTriggers,
      canCreateApplicationTriggers,
      canUpdateApplicationTriggers,
      canDeleteApplicationTriggers,
    } = useTriggersSetting()

    return {
      fetchProgramTriggers,
      fetchTriggers,
      fetchEmailTemplates,
      saveTriggers,
      canCreateApplicationTriggers,
      canUpdateApplicationTriggers,
      canDeleteApplicationTriggers,
    }
  },
  methods: {
    async setModalData() {
      this.isLoading = true
      await this.setStatusTriggersData()
      await this.fetchTriggers().then(response => {
        this.triggersOptions = response.map(item => (
          {
            value: item,
            label: item,
          }
        ))
      })
      await this.fetchEmailTemplates().then(response => {
        this.emailTemplatesOptions = response
      })
      this.isLoading = false
    },
    resetModalData() {
      this.rowsCount = 0
      this.statusTriggers = []
      this.emailTemplatesOptions = []
      this.triggersOptions = []
      this.validationErrors = []
      this.isLoading = false
    },
    async setStatusTriggersData() {
      const queryParams = {
        programId: this.programId,
        statusId: this.selectedStatus.id,
      }
      await this.fetchProgramTriggers(queryParams)
        .then(response => {
          this.statusTriggers = response.map((item, index) => (
            {
              frontID: index,
              delete: false,
              ...item,
            }
          ))

          this.rowsCount = this.statusTriggers.length
        })
    },
    isFrontIDExists(key) {
      return this.statusTriggers.some(field => field.frontID === key)
    },
    addNewTriggerRow() {
      do {
        this.rowsCount += 1
      } while (this.isFrontIDExists(this.rowsCount))

      this.statusTriggers.push({
        id: null,
        type: 'Status',
        for_id: this.selectedStatus.id,
        to_id: null,
        program_id: this.programId,
        trigger: null,
        email_template_id: null,
        delete: false,
        frontID: this.rowsCount,
        message_text: null,
        newField: true,
      })
    },
    removeRow(ID) {
      const curItem = this.statusTriggers.findIndex(item => item.frontID === ID)
      this.statusTriggers[curItem].delete = true

      if (this.statusTriggers[curItem].newField) {
        this.statusTriggers.splice(curItem, 1)
      }
    },
    async postStatusTriggers(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(reject => {
        this.$refs.setupStatusEvents.validate().then(success => {
          if (success) {
            this.saveTriggers(this.statusTriggers)
              .then(response => {
                if (response.data && response.status === 422) {
                  const validationErrors = Object.values(response.data.errors)
                  this.validationErrors = validationErrors.flat()
                } else {
                  this.validationErrors = []
                  this.$nextTick(() => {
                    this.$bvModal.hide('setup-status-events')
                  })
                }
              })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
