<template>
  <b-card
    class="status-card"
  >
    <template #header>
      <div class="w-100 d-flex align-items-center justify-content-between border-bottom pb-1">
        <h5 class="mb-0 d-flex align-items-center">
          <img
            src="@/assets/images/svg/more-vertical.svg"
            class="mr-1"
            alt=""
          >
          <span
            v-if="!isNameEditing"
            @dblclick="editStatusName"
          >{{ status.name }}</span>
          <b-form-input
            v-else
            ref="statusName"
            v-model="status.name"
            v-click-outside="updateStatusName"
            placeholder="Status Name"
            @keyup.enter="updateStatusName"
          />
        </h5>
        <b-dropdown
          v-if="!isNameEditing"
          variant="link"
          toggle-class="mx-auto p-0"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="align-middle text-primary"
            />
          </template>

          <b-dropdown-item
            @click="editStatusName"
          >
            Rename
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canReadApplicationTriggers"
            @click="showSetupStatusEventModal"
          >
            Set up event
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canAddOrRemoveApplicationTags"
            @click="showSetupStatusTagsModal"
          >
            Set up tags
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="status.steps.length > 0"
            variant="danger"
            @click="deleteStatus"
          >
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <draggable
      :list="status.steps"
      :group="{ name: 'status' }"
      @end="finishMove"
    >
      <StepItem
        v-for="step in status.steps"
        :key="step.id"
        :status="status"
        :step="step"
        :can-read-application-triggers="canReadApplicationTriggers"
      />
    </draggable>

    <b-button
      class="w-100"
      variant="outline-primary"
      @click="showAddStepModal"
    >
      Add step
      <feather-icon
        icon="PlusIcon"
        size="16"
      />
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormInput,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'
import store from '@/store'

import StepItem from '@/views/admin/program/program-registration-statuses/status-card/StepItem.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,

    StepItem,
    draggable,
  },
  props: {
    status: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isNameEditing: false,
    }
  },
  computed: {
    canReadApplicationTriggers() {
      return this.$can('read', permissionSubjects.ApplicationTriggers)
    },
    canAddOrRemoveApplicationTags() {
      return this.$can('add' && 'remove', permissionSubjects.Tags)
    },
  },
  methods: {
    showAddStepModal() {
      store.commit('app-program/SET_SELECTED_STATUS', this.status)
      store.commit('app-program/SET_STEP_MODAL_TYPE', 'create')
      this.$bvModal.show('edit-step-modal')
    },
    showSetupStatusEventModal() {
      store.commit('app-program/SET_SELECTED_STATUS', this.status)
      this.$bvModal.show('setup-status-events')
    },
    showSetupStatusTagsModal() {
      store.commit('app-program/SET_SELECTED_STATUS', this.status)
      this.$bvModal.show('setup-status-tags')
    },
    editStatusName() {
      this.isNameEditing = true
      this.$nextTick(() => {
        this.$refs.statusName.focus()
      })
    },
    async updateStatusName() {
      this.isNameEditing = false
      await store.dispatch('app-program/saveRegistrationForm', this)
    },
    async deleteStatus() {
      store.commit('app-program/DELETE_STATUS', this.status)
      await store.dispatch('app-program/saveRegistrationForm', this)
    },
    async finishMove() {
      await store.dispatch('app-program/saveRegistrationForm', this)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-card {
  align-self: start;
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  width: 270px;
  flex-basis: 270px;
  flex-shrink: 0;
  margin: 0 10px 20px;
}
</style>
