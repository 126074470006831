<template>
  <b-modal
    id="editing-settings-modal"
    ref="editing-settings-modal"
    centered
    title="Editing Settings"
    ok-title="Save Changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @ok="handleForm"
    @hidden="initEditingSettingsData"
  >
    <validation-observer
      ref="editingSettingsObserver"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <span class="text-muted">* Besides for the steps before payment after payment made</span>
        </b-col>

        <!--Edit All Steps-->
        <b-col
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <label
            class="mr-1 mb-0 col-form-label"
          >
            Enable Edit All Steps
          </label>
          <b-form-checkbox
            v-model="editingSettingsData.enable_edit_all_steps"
            switch
            class="d-inline-block ml-50 mr-1"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

        <!--Disable Editing Steps With Statuses-->
        <b-col
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <label
            class="mr-1 mb-0 col-form-label"
          >
            Disable Editing Steps With Specific Statuses
          </label>
          <b-form-checkbox
            v-model="editingSettingsData.disable_editing_steps"
            switch
            class="d-inline-block ml-50 mr-1"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

        <!--Selection of step statuses-->
        <b-col
          v-if="editingSettingsData.disable_editing_steps"
          cols="12"
        >
          <b-form-group
            label="Select step statuses for disable editing"
          >
            <validation-provider
              #default="{ errors }"
              name="Step Statuses"
              rules="required"
            >
              <v-select
                v-model="editingSettingsData.specific_step_statuses"
                :options="stepStatusesList"
                label="text"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Select Statuses"
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--Disable Editing Whole Application-->
        <b-col
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <label
            class="mr-1 mb-0 col-form-label"
          >
            Disable Editing Whole Application
          </label>
          <b-form-checkbox
            v-model="editingSettingsData.disable_editing_whole_application"
            switch
            class="d-inline-block ml-50 mr-1"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

        <!--Disable Editing Whole Application After Date-->
        <b-col
          v-if="!editingSettingsData.enable_edit_all_steps"
          cols="12"
          class="d-flex align-items-center mb-2"
        >
          <label
            class="mr-1 mb-0 col-form-label"
          >
            Use Disabling After Date
          </label>
          <b-form-checkbox
            v-model="editingSettingsData.use_disabling_after_specific_date"
            switch
            class="d-inline-block ml-50 mr-1"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

        <!--Date Picker-->
        <b-col
          v-if="editingSettingsData.use_disabling_after_specific_date"
          cols="6"
        >
          <b-form-group
            label="Disable Date"
          >
            <validation-provider
              #default="{ errors }"
              name="disable date"
              rules="required"
            >
              <flat-pickr
                v-model="editingSettingsData.specific_date"
                class="form-control"
                :class="{'is-invalid': errors.length > 0 }"
                placeholder="Time Picker"
                :config="{
                  enable: [date => date >= new Date()],
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,

    flatPickr,
    vSelect,
  },
  data() {
    return {
      stepStatusesList: [
        'Draft',
        'In Review',
        'Approved',
        'Rejected',
      ],
      editingSettingsData: {},
      required,
    }
  },
  computed: {
    ...mapGetters({
      editingSettings: 'app-program/getEditingSettings',
    }),
  },
  watch: {
    'editingSettingsData.enable_edit_all_steps': function fn(val) {
      if (val) {
        this.editingSettingsData.disable_editing_steps = false
        this.editingSettingsData.disable_editing_whole_application = false
        this.editingSettingsData.use_disabling_after_specific_date = false
        this.specific_date = null
        this.specific_step_statuses = null
      }
    },
    'editingSettingsData.disable_editing_steps': function fn(val) {
      if (val) {
        this.editingSettingsData.enable_edit_all_steps = false
        this.editingSettingsData.disable_editing_whole_application = false
      } else {
        this.editingSettingsData.specific_step_statuses = null
      }
    },
    'editingSettingsData.disable_editing_whole_application': function fn(val) {
      if (val) {
        this.editingSettingsData.enable_edit_all_steps = false
        this.editingSettingsData.disable_editing_steps = false
      }
    },
    'editingSettingsData.use_disabling_after_specific_date': function fn(val) {
      if (!val) {
        this.editingSettingsData.specific_date = null
      }
    },
    editingSettingsData: {
      deep: true,
      async handler(val) {
        if (!val.disable_editing_steps && !val.disable_editing_whole_application) {
          this.editingSettingsData.enable_edit_all_steps = true
        }
      },
    },
  },
  methods: {
    setModalData() {
      this.initEditingSettingsData()
    },
    initEditingSettingsData() {
      this.editingSettingsData = {
        enable_edit_all_steps: this.editingSettings.enable_edit_all_steps ?? true,
        disable_editing_steps: this.editingSettings.disable_editing_steps ?? false,
        disable_editing_whole_application: this.editingSettings.disable_editing_whole_application ?? false,
        use_disabling_after_specific_date: this.editingSettings.use_disabling_after_specific_date ?? false,

        specific_date: this.editingSettings.specific_date,
        specific_step_statuses: this.editingSettings.specific_step_statuses ?? ['Approved'],
      }
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.editingSettingsObserver.validate().then(success => {
          if (success) {
            resolve(true)
            this.saveData()
          } else {
            reject()
          }
        })
      })
    },
    async saveData() {
      store.commit('app-program/SET_EDITING_SETTINGS', this.editingSettingsData)
      this.$bvModal.hide('editing-settings-modal')
      await store.dispatch('app-program/saveRegistrationForm', this)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
