<template>
  <b-modal
    id="bulk-download-files"
    ref="bulk-download-files"
    centered
    title="Files Bulk Download"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @hidden="$emit('reset')"
    @show="prepareFiles"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row>
        <!--  Event  -->
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Files"
          >
            <v-select
              v-model="chosenFiles"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filesOptionsList"
              :placeholder="selectStatus"
              multiple
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-overlay>

    <div
      v-if="validationErrors"
      class="form-group pt-1"
    >
      <p
        v-for="(validationError, index) in validationErrors"
        :key="index"
        class="text-center text-danger"
      >
        {{ validationError }}
      </p>
    </div>
    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="isLoading"
        @click="$refs['bulk-download-files'].hide()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="!chosenFiles.length || isLoading"
        @click="downloadZip"
      >
        Download
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'

export default {
  name: 'BulkDownloadFilesModal',
  components: {
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BOverlay,

    vSelect,
  },
  mixins: [downloadExportFile],
  props: {
    programId: {
      type: String,
      required: true,
    },
    programName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chosenFiles: [],
      filesOptionsList: [],
      selectStatus: 'Loading...',
      isLoading: false,
      validationErrors: [],
    }
  },
  computed: {
    programNameCleaned() {
      return this.programName.replace(/\s/g, '-')
    },
  },
  setup() {
    const {
      getProgramFiles,
      fetchZipFiles,
    } = useProgramsSetting()

    return {
      getProgramFiles,
      fetchZipFiles,
    }
  },
  methods: {
    async prepareFiles() {
      this.validationErrors = []
      this.selectStatus = 'Loading...'
      this.chosenFiles = []
      this.filesOptionsList = []

      await this.getProgramFiles(this.programId).then(response => {
        this.filesOptionsList = response
        this.selectStatus = 'Select Files'
      })
    },
    async downloadZip() {
      this.validationErrors = []
      this.isLoading = true
      const queryData = {
        program_id: this.programId,
        file_names: this.chosenFiles,
      }
      await this.fetchZipFiles(queryData).then(response => {
        if (!response) {
          this.validationErrors = ['Something went wrong']
          return
        }
        if (response && response.size === 0) {
          this.validationErrors = ['The Zip File is empty']
          return
        }
        this.downloadFile(response, this.programNameCleaned, 'zip')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
